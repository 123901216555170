import { datadogRum } from "@datadog/browser-rum";

import { analyticsEnabled } from "./data.js";

interface PerformanceLongAnimationFrameTiming extends PerformanceEntry {
  blockingDuration?: number;
  scripts?: { invoker?: string; invokerType?: string }[];
}

/**
 * Observes long animation frames using the `PerformanceObserver` API and submits that info to Datadog.
 */
export function startLoggingLongAnimationFrames(): void {
  if (
    analyticsEnabled &&
    PerformanceObserver.supportedEntryTypes.includes("long-animation-frame")
  ) {
    new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        const { blockingDuration, scripts } =
          entry as PerformanceLongAnimationFrameTiming;
        const invoker = scripts?.[0]?.invoker;
        const invokerType = scripts?.[0]?.invokerType;
        datadogRum.addAction("logLongAnimationFrame", {
          blockingDuration,
          invoker,
          invokerType,
        });
      });
    }).observe({ type: "long-animation-frame", buffered: true });
  }
}
