import { datadogRum } from "@datadog/browser-rum";

import { analyticsEnabled } from "./data.js";

interface MemoryInfo {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

declare global {
  interface Performance {
    memory?: MemoryInfo;
  }
}

// Randomized intervals give more accurate memory usage distributions.
// See https://web.dev/articles/monitor-total-page-memory-usage
const MEAN_INTERVAL_IN_MS = 5 * 60 * 1000;
function getMeasurementInterval(): number {
  return -Math.log(Math.random()) * MEAN_INTERVAL_IN_MS;
}

function recordMemoryUsage(): void {
  if (window.performance.memory == null) {
    return;
  }

  // `performance.memory` isn't a great API and doesn't work in all browsers.
  // See https://developer.mozilla.org/en-US/docs/Web/API/Performance/memor
  // I really want to use `measureUserAgentSpecificMemory` instead,
  // which is a much better API overall, but unfortunately in only available in
  // a `crossOriginIsolated` context, which I don't think we can achieve.
  // See https://web.dev/articles/coop-coep
  const { totalJSHeapSize, usedJSHeapSize } = window.performance.memory;

  datadogRum.addAction("logMemoryUsage", {
    totalJSHeapSize,
    usedJSHeapSize,
  });
}

function onTimeout(): void {
  // Don't bother recording data unless the page is visible.
  // This should save us from recording a ton of non-meaningful samples when the user isn't using the page.
  if (document.visibilityState === "visible") {
    recordMemoryUsage();
  }

  window.setTimeout(onTimeout, getMeasurementInterval());
}

export function startLoggingMemoryUsage(): void {
  if (analyticsEnabled && window.performance.memory != null) {
    window.setTimeout(onTimeout, getMeasurementInterval());
  }
}
