// TODO: Catch?
export const asyncEvery = async (
  promises: Promise<boolean>[],
): Promise<boolean> => {
  return (await Promise.all(promises)).every((result) => result);
};

// TODO: catch?
export const asyncSome = async (
  promises: Promise<boolean>[],
): Promise<boolean> => {
  return (await Promise.all(promises)).some((result) => result);
};

// async counterpart to Array.prototype.find
export async function asyncFind<T>(
  arr: T[],
  cb: (el: T) => Promise<boolean>,
): Promise<T | undefined> {
  for (const el of arr) {
    if (await cb(el)) {
      return el;
    }
  }
}

/**
 * @param delay how long to sleep for in ms
 *
 * Basically an async/await wrapper around `setTimeout`
 */
export function sleep(delay: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

/**
 * Converts a synchronous `for` loop into an async `for await` loop that batches its iterations
 * to prevent the loop from hogging the javascript event loop for CPU intensive tasks.
 *
 * @param elements Iterable to iterate over
 * @param maxBatchTime The maximum time in ms the loop should execute before sleeping
 * @param delay How long in ms to sleep between each batch
 */
export async function* yieldingIterable<T>(
  elements: Iterable<T>,
  maxBatchTime: number,
  delay = 0,
): AsyncIterable<T> {
  let lastPause = performance.now();
  for (const element of elements) {
    if (performance.now() - lastPause > maxBatchTime) {
      // eslint-disable-next-line no-await-in-loop -- being able to optionally sleep during iteration is the point of this utility
      await sleep(delay);
      lastPause = performance.now();
    }
    yield element;
  }
}

/**
 * Calls @param callback periodically (every @param periodMs milliseconds)
 * until @param promise is settled.
 */
export async function periodicallyWhile<T>(
  promise: Promise<T>,
  callback: () => void,
  periodMs: number,
): Promise<T> {
  let settled = false;

  function check(): void {
    if (!settled) {
      callback();
      setTimeout(check, periodMs);
    }
  }
  setTimeout(check, periodMs);

  try {
    return await promise;
  } finally {
    settled = true;
  }
}
