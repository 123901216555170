/* eslint-disable no-await-in-loop */
import { sleep } from "./async";

export interface RetryArgs<R> {
  func: () => Promise<R>;
  retryCount: number;
  interval: number; // in millis
}

export async function retry<R>({
  func,
  interval,

  retryCount,
}: RetryArgs<R>): Promise<R> {
  for (let i = 0; i <= retryCount; i++) {
    try {
      return await func();
    } catch (err) {
      if (i < retryCount - 1) {
        await sleep(interval);
      } else {
        throw err;
      }
    }
  }

  throw new Error("Impossible to reach.");
}
