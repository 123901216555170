export type ProductionEnvironment =
  | "prod"
  | "staging"
  | "develop"
  | "local"
  | "self-hosted"
  | "single-tenant"
  | "unknown";

export const GLOBAL_ORG_SHARING_ENVIRONMENTS: ProductionEnvironment[] = [
  "local",
  "self-hosted",
  "single-tenant",
  "develop",
  "unknown",
];

export const getProductionEnvironment = (
  url: string,
): ProductionEnvironment => {
  let hostname;
  if (url.match(/https?:\/\//)) {
    try {
      hostname = new URL(url).hostname;
    } catch (e) {
      console.error(e);
      throw new Error(
        `Invalid hostname specified for production environment: ${hostname}`,
      );
    }
  } else {
    hostname = url;
  }

  const [initialSubDomain, ...rootDomains] = hostname.split(".");
  const rootDomain = rootDomains.join(".");
  if (!["hex.site", "hex.tech"].includes(rootDomain)) {
    if ("snowflakecomputing.app" === rootDomain) {
      return "prod";
    }
    if (initialSubDomain?.match(/localhost(:\d+)?/)) {
      return "local";
    }
  }
  const subDomain =
    initialSubDomain?.includes("develop") || initialSubDomain?.includes("dev-")
      ? "develop"
      : initialSubDomain;

  // TODO(FOUND-179): Remove this manual check once we have consolidated RC and MT via Terraform.
  // We need this check for now to ensure that rc/hex and rc/hex-testing both use the same feature flag
  // configuration that they had on the MT stack.
  switch (subDomain) {
    case "app":
    case "eu":
    case "hc":
    case "rc":
      return "prod";
    case "staging":
      return "staging";
    case "develop":
      return "develop";
    default:
      return "single-tenant";
  }
};

export function shouldAllowDangerouslyUnsafeLogging({
  environment,
  nodeEnv,
}: {
  environment: ProductionEnvironment;
  nodeEnv?: string;
}): boolean {
  return (
    nodeEnv === "development" ||
    ["local", "develop", "staging"].includes(environment)
  );
}
