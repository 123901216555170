/**
 * @file Tracks when an embed is "done" loading.
 * This lets the code in `embedEntry.ts` notify the SharedWorker that it is done loading,
 * which lets the SharedWorker in turn tell other embeds to start loading.
 *
 * This is the _only_ usecase that this code should be used for.
 * Given this usecase, we don't have to have `markEmbedAsLoaded` called
 * at the exact right time - it's okay if it gets called a touch early for example.
 *
 * Currently, at the time of writing,  to support our embedding usecases,
 * we only need to call this on
 * 1) The login page and 2) When an app is loaded
 */

let embedLoadedCallback: (() => void) | undefined;
let embedLoaded = false;

export function setOnEmbedLoadedCallback(callback: () => void): void {
  embedLoadedCallback = callback;
}

/** Mark the current page as loaded (or mostly loaded). */
export function markEmbedAsLoaded(): void {
  if (embedLoaded) return;
  embedLoaded = true;

  embedLoadedCallback?.();
}
