import { datadogRum } from "@datadog/browser-rum";

import { analyticsEnabled } from "./data.js";

// So why do we have both of the fields below?
// Say a really slow event happens that takes 1000ms to process,
// and then 10ms later a few trivially fast events happens.
//
// These events might look like this:
// slow event { totalTime: 1000, processingTime: 1000, waitingTime: 0 }
// second event { totalTime: 990, processingTime: 1, waitingTime: 989 }
// third event { totalTime: 990, processingTime: 1, waitingTime: 989 }
//
// `PerformanceObserver`'s `durationThreshold` option only filters on the total time of the event,
// so it would provide us data on all three events.
// But we don't really care about the latter two events,
// since the only reason they're slow is because of the first event.
// So we also filter on `processingTime` to make sure we're not spamming Datadog with useless data.

/** Only track events that also have at least this long of a delay until the next paint. */
const MIN_TOTAL_TIME_MS = 60;
/** Only track events that individually take at least this long to process */
const MIN_PROCESSING_TIME_MS = 15;

/**
 * Returns a string identifying an element in the form:
 * data-cy-value:tagName#id.class1.class2
 */
function getElementName(ele: HTMLElement | SVGElement): string {
  const cyData = ele.dataset["cy"];
  const elementId = ele.id;
  const elementTag = ele.localName;
  const elementClasses = ele.classList.value.replaceAll(" ", ".");
  return `${cyData ? cyData + ":" : ""}${elementTag}${
    elementId ? "#" + elementId : ""
  }${elementClasses ? "." + elementClasses : ""}`;
}

interface ElementDetails {
  /** Name of the event target */
  eventTarget: string;
  /** data-cy value of the event target, if any */
  eventTargetCyData: string;
  /** Name of the closest ancestor element (or the element itself) that has a data-cy identifier */
  eventTargetTaggedParent: string;
  /** data-cy value of the closest ancestor with one, if any */
  eventTargetTaggedParentCyData: string;
}

function getElementDetails(ele: Node | null): ElementDetails {
  if (!(ele instanceof HTMLElement) && !(ele instanceof SVGElement))
    return {
      eventTarget: "Unknown",
      eventTargetCyData: "None",
      eventTargetTaggedParent: "None",
      eventTargetTaggedParentCyData: "None",
    };

  const taggedParent = ele.closest("[data-cy]");
  let eventTargetTaggedParent = "None";
  let eventTargetTaggedParentCyData = "None";
  if (
    taggedParent instanceof HTMLElement ||
    taggedParent instanceof SVGElement
  ) {
    eventTargetTaggedParent = getElementName(taggedParent);
    eventTargetTaggedParentCyData = taggedParent.dataset["cy"] ?? "None";
  }

  return {
    eventTarget: getElementName(ele),
    eventTargetCyData: ele.dataset["cy"] ?? "None",
    eventTargetTaggedParent,
    eventTargetTaggedParentCyData,
  };
}

function onSlowEventsObserved(entries: PerformanceObserverEntryList): void {
  const eventEntries = entries.getEntriesByType(
    "event",
  ) as PerformanceEventTiming[];

  for (const entry of eventEntries) {
    const eventProcessingTime = entry.processingEnd - entry.processingStart;

    if (eventProcessingTime < MIN_PROCESSING_TIME_MS) {
      continue;
    }

    const context = {
      eventName: entry.name,
      eventWaitingTime: entry.processingStart - entry.startTime,
      eventProcessingTime,
      eventTotalTime: entry.duration,
      ...getElementDetails(entry.target),
    };
    datadogRum.addAction("logSlowEvent", context);
  }
}

/** Observes slow events using the `PerformanceObserver` API and submits that info to Datadog. */
export function startLoggingSlowEvents(): void {
  if (!analyticsEnabled) return;
  if (!PerformanceObserver.supportedEntryTypes.includes("event")) return;

  const observer = new PerformanceObserver(onSlowEventsObserved);

  // TypeScript doesn't know about the `durationThreshold` option yet,
  // so build up our options in two steps to keep it from complaining
  const baseOptions: PerformanceObserverInit = {
    type: "event",
  };
  const allOptions = {
    ...baseOptions,
    durationThreshold: MIN_TOTAL_TIME_MS,
  };

  observer.observe(allOptions);
}
